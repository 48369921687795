module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://michaelroseorg.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KHN5Q78","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michael Rose","short_name":"Michael Rose","start_url":"/","background_color":"#ffffff","theme_color":"#2653C6","display":"minimal-ui","icon":"src/images/Favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5c6a3a4286c91253ae07b9056f59d242"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
